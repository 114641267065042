// Here you can add other styles
.square-container {
    width: 25%;
    height: 100%;
    overflow: hidden;
}

#container {
    width: 50%; /* desired width */
}

#element {
    height: 0;
    padding-bottom: 100%;
}