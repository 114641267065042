button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.on {
  color: #ffd900;
}
.off {
  color: #ffd90073;
}