.back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 70px;
    width: 70px;
    z-index: 1;
    background: white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
}

.card-container {
    box-shadow: 0 -8px 16px rgba(0, 0, 0, 0.2);
}

.card-title {
    font-weight: bolder;
    font-size: x-large;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
}


.tag {
    min-width: 45%;
    font-weight: bold;
    border: 1px solid #ccc;
    border-radius: 100px;
    padding: 8px 16px;
    margin-right: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.c-button-group {
    display: flex;
    align-items: center;
    height: 100%;
    width: fit-content;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.c-button {
    height: 100%;
    color: black;
    font-size: x-large;
    padding: 12px;
}

@keyframes zoomInOut2 {
    0% {
        transform: scale(1.0);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1.0);
    }
}

.zoom-in-out2 {
    animation: zoomInOut2 20s infinite;
}

@keyframes wiggle {

    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: scale(1.1);
    }

    75% {
        transform: scale(1.0);
    }
}