/* CSS for the zoom in and out animation */
@keyframes zoomInOut {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0.8);
    }
}

/* Apply animation to the card-overlay when showCardOverlay is true */
.zoom-in-out {
    animation: zoomInOut 5s infinite;
}